<template>
	<div>
		<title-bar :title-stack="titleStack"/>

		<section class="section is-main-section">
			<card-component :title="'注文番号：' + number" icon="" :hasButtonSlot="true">
				<b-button slot="button" :disabled="isFinish" type="is-info is-small" @click="isMenuModalActive = true">オーダー追加</b-button>
				<b-button slot="button" type="is-success is-small" @click="onInvoiceClick" :loading="isInvoiceLoading">納品書作成</b-button>

				<div class="detail_header">
					<div class="detail_header-left">
						<p class="" style="font-weight:bold;">【送付先】</p>
						<p class="">{{ storeName }}</p>
						<p class="">〒{{ storePostal }}</p>
						<p class="">{{ storeAddress }}</p>
						<p v-if="storeBuilding" class="">{{ storeBuilding }}</p>
						<p class="">{{ storeTel }}</p>
					</div>

					<div class="detail_header-right">
						<ul class="tf">
							<li>
								<p class="tf-title">※送料</p>
								<p class="tf-price">{{ shippingFee.toLocaleString() }}円</p>
							</li>
							<li>
								<p class="tf-title">小計</p>
								<p class="tf-price">{{ subTotal.toLocaleString() }}円</p>
							</li>
							<li>
								<p class="tf-title">消費税(8%)</p>
								<p class="tf-price">{{ taxClass2.toLocaleString() }}円</p>
							</li>
							<li>
								<p class="tf-title">※消費税(10%)</p>
								<p class="tf-price">{{ taxClass1.toLocaleString() }}円</p>
							</li>
							<li>
								<p class="tf-title">合計</p>
								<p class="tf-price">{{ grandTotal.toLocaleString() }}円</p>
							</li>
						</ul>
					</div>
				</div>

				<b-table
					:loading="isLoading"
					:paginated="false"
					:per-page="$consts.TABLE_PER_PAGE"
					:hoverable="true"
					default-sort=""
					:data="details"
					>

					<b-table-column label="商品名" field="menu_name" sortable v-slot="props">
						<span v-if="props.row.menu_tax_class_id === 1">※{{ props.row.menu_name }}</span>
						<span v-else>{{ props.row.menu_name }}</span>
					</b-table-column>
					<b-table-column label="品番" field="menu_code" sortable v-slot="props">
						{{ props.row.menu_code }}
					</b-table-column>
					<b-table-column label="数量" field="quantity" sortable numeric v-slot="props">
						{{ props.row.quantity }}{{ props.row.menu_unit }}
					</b-table-column>
					<b-table-column label="納期" field="menu_lead_time" sortable v-slot="props">
						{{ props.row.menu_lead_time }}
					</b-table-column>
					<b-table-column v-if="isFinish" label="発送" field="is_delivery" v-slot="props">
						<b-icon v-if="!props.row.menu_fp_item_code" icon="check" icon-pack="fas" type="is-success"/>
						<span v-else>直送</span>
					</b-table-column>
					<b-table-column v-if="!isFinish" label="発送" field="is_delivery" v-slot="props">
						<b-checkbox v-if="!props.row.menu_fp_item_code" v-model="deliveryChecks" :native-value="props.row.id"/>
						<span v-else>直送</span>
					</b-table-column>
					<b-table-column label="" field="quantity_edit" v-slot="props">
						<b-button v-if="!isFinish" type="is-warning is-small" @click="onQuantityEditClick(props.row.id)">数量変更</b-button>
					</b-table-column>
				</b-table>

				<b-field label="コメント（お客様へ送信するメールに表示されます）" class="mt-40">
					<b-input type="textarea" v-model="comment"></b-input>
				</b-field>

				<b-field grouped class="mt-40">
					<div class="control">
						<b-button @click="$router.go(-1)">戻る</b-button>
					</div>
					<div class="control">
						<b-button :disabled="isFinish" class="ml-20" type="is-info" :loading="isTempSaveLoading" @click="onTempSaveClick">一時保存</b-button>
					</div>
					<div class="control">
						<b-button class="ml-20" type="is-primary" :loading="isDeliveryLoading" @click="onDeliveryClick" :disabled="isDeliveryDisabled">発送連絡</b-button>
					</div>
				</b-field>
			</card-component>
		</section>

		<b-modal :active.sync="isEditModalActive" :width="400" class="edit-modal">
			<div class="modal-card" style="width: auto">
				<header class="modal-card-head">
					<p class="modal-card-title">数量変更</p>
				</header>

				<section class="modal-card-body">
					<ul class="mb-20">
						<li>商品名：{{ editName }}</li>
						<li>品番：{{ editCode}}</li>
						<li>数量：{{ editBeforeQuantity + editUnit }}</li>
					</ul>
					<b-field>
						<b-numberinput v-model="editQuantity" min="0"></b-numberinput>
					</b-field>
					<p style="color:red;font-size:14px;">※オーダーを削除する場合は数量を0または空欄にしてください</p>
				</section>

				<footer class="modal-card-foot">
					<b-button label="キャンセル" @click="isEditModalActive = false" />
					<b-button class="ml-30" label="変更" type="is-primary" :loading="isEditLoading" @click="onEditClick" />
				</footer>
			</div>
		</b-modal>

		<menu-modal
			:isActive="isMenuModalActive"
			:orderId="$route.params.orderId"
			@cancel="isMenuModalActive = false"
			@update="onOrderUpdateClick"
			></menu-modal>
	</div>
</template>

<style lang="scss" scoped>
@import '../scss/_order-detail.scss';
</style>

<script>
import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import MenuModal from '@/components/MenuModal'

export default {
	name: 'OrderDetail',
	components: {
		CardComponent,
		TitleBar,
		MenuModal,
	},
	data () {
		return {
			isLoading: false,
			number: '',
			date: '',
			storeName: '',
			storePostal: '',
			storeAddress: '',
			storeTel: '',
			storeBuilding: '',
			subTotal: 0,
			taxClass1: 0,
			taxClass2: 0,
			shippingFee: 0,
			grandTotal: 0,
			isFinish: false,
			comment: '',
			details: [],
			deliveryChecks: [],
			isInvoiceLoading: false,
			isDeliveryLoading: false,
			isTempSaveLoading: false,
			isEditModalActive: false,
			editId: '',
			editName: '',
			editBeforeQuantity: 0,
			editQuantity: 0,
			editCode: '',
			editUnit: '',
			isEditLoading: false,
			isMenuModalActive: false,
		}
	},
	computed: {
		titleStack () {
			return [
				this.$route.meta.title,
			]
		},
		isDeliveryDisabled: function() {
			return this.details.filter(val => !val.menu_fp_item_code).length > this.deliveryChecks.length;
		},
	},
	mounted() {
		this.init();
	},
	methods: {
		onOrderUpdateClick: async function(modalData) {
			this.$utils.log('onOrderUpdateClick');
			//this.$utils.log(modalData);
			let orders = {};
			for (let i in modalData.orders) {
				orders[modalData.orders[i].id] = modalData.orders[i].qty;
			}
			//this.$utils.log(orders);

			let data = {
				"store_id": '',
				"order_id": this.$route.params.orderId,
				"orders": orders,
			};
			this.$utils.log(data);
 
			this.isLoading = true;

			try {
				let ep = 'orders/update';

				let res = await this.$api.post(ep, data, true);
				this.$utils.log(res);
				this.getData();
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isLoading = false;
			}
		},
		onQuantityEditClick: function(detailId) {
			let detail = this.details.filter(val => val.id === detailId)[0];
			this.editId = detailId;
			this.editName = detail.menu_name;
			this.editCode = detail.menu_code;
			this.editUnit = detail.menu_unit;
			this.editBeforeQuantity = detail.quantity;
			this.editQuantity = detail.quantity;
			this.isEditModalActive = true;
		},
		onEditClick: async function() {
			let msg = this.editQuantity > 0 ? '数量を変更します。よろしいですか？' : '商品を削除します。オーダー内の商品がすべてなくなった場合は、オーダーデータも削除されます。よろしいですか？';

			if (!window.confirm(msg)) return false;

			this.isEditLoading = true;
			let data = {
				"quantity": this.editQuantity,
			};

			this.$utils.log(data);

			try {
				let ep = 'order-details/' + this.editId;

				let res = await this.$api.put(ep, data, true);
				this.$utils.log(res);
				this.$utils.toastSuccess('数量を変更しました');

				if (res.data.is_noorder) {
					this.$router.push({ name: 'order' });
				} else {
					this.getData();
				}
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isEditLoading = false;
				this.isEditModalActive = false;
			}
		},
		onTempSaveClick: async function() {
			this.isTempSaveLoading = true;
			let data = {
				"id": this.$route.params.orderId,
				"comment": this.comment,
				"delivery_checks": this.deliveryChecks,
			};

			this.$utils.log(data);

			try {
				let ep = 'orders/temp-save';

				let res = await this.$api.post(ep, data, true);
				this.$utils.log(res);
				this.$utils.toastSuccess('一時保存しました');
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isTempSaveLoading = false;
			}
		},
		onInvoiceClick: async function() {
			this.isInvoiceLoading = true;

			let ep = 'orders/invoice';
			this.$utils.log(ep);

			let data = {
				"id": this.$route.params.orderId,
			};
			this.$utils.log(data);

			try {
				let res = await this.$api.post(ep, data, true);
				this.$utils.log(res);

				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(this.$utils.toBlob(res.data.body, res.data.mime_type))
				link.download = res.data.file_name;
				link.click()
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isInvoiceLoading = false;
			}
		},
		onDeliveryClick: async function() {
			if (!window.confirm('発送済みとして加盟店に納品書を送信します。よろしいですか？')) return false;

			this.isLoading = true;
			let data = {
				"id": this.$route.params.orderId,
				"comment": this.comment,
				"is_finish": true,
			};

			this.$utils.log(data);

			try {
				let ep = 'orders/finish';

				let res = await this.$api.post(ep, data, true);
				this.$utils.log(res);

				this.$utils.toastSuccess('発送済みにしました');
				this.$router.push({ name: 'order' });
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isLoading = false;
			}
		},
		init: function() {
			this.getData();
		},
		getData: async function() {
			this.isLoading = true;
			let ep = 'orders/' + this.$route.params.orderId;
			this.$utils.log(ep);

			try {
				let res = await this.$api.get(ep, null, true);
				this.$utils.log(res);
				this.number = res.data.number;
				this.date = res.data.date;
				this.storeName = res.data.store_name;
				this.storePostal = res.data.store_postal;
				this.storeAddress = res.data.store_address;
				this.storeBuilding = res.data.store_building;
				this.storeTel = res.data.store_tel;
				this.subTotal = res.data.sub_total;
				this.taxClass1 = res.data.tax_class1;
				this.taxClass2 = res.data.tax_class2;
				this.grandTotal = res.data.grand_total;
				this.shippingFee = res.data.shipping_fee;
				this.comment = res.data.comment;
				this.isFinish = res.data.is_finish;
				this.details = res.data.details;
				this.deliveryChecks = res.data.delivery_checks;
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isLoading = false;
			}
		},
	}
}
</script>
