<template>
	<div>
		<title-bar :title-stack="titleStack"/>

		<section class="section is-main-section">
			<card-component :title="'発行日：' + date" icon="" :hasButtonSlot="true">
				<b-button slot="button" type="is-primary is-small" @click="onMailClick" :loading="isMailLoading">メール送信</b-button>
				<b-button slot="button" type="is-info is-small" @click="onDlClick" :loading="isDlLoading">ダウンロード</b-button>

				<ul class="detail_header">
					<li><span class="detail_header-title">伝票番号：</span><span>{{ number }}</span></li>
					<li><span class="detail_header-title">加盟店：</span><span>{{ storeName }}</span></li>
				</ul>

				<credit-note-item-table class="mt-30" :items="items" :deleteShow="false"></credit-note-item-table>

				<div style="font-weight:bold;">コメント</div>
				<div style="white-space: pre-line;">{{ comment }}</div>

				<b-field grouped class="mt-40">
					<div class="control">
						<b-button @click="$router.push({ name: 'credit-note' })">一覧に戻る</b-button>
					</div>
					<div class="control">
						<b-button class="ml-20" type="is-warning" @click="$router.push({ name: 'credit-note-edit', creditNoteId: id })">編集</b-button>
					</div>
				</b-field>

			</card-component>
		</section>
	</div>
</template>

<style lang="scss" scoped>
.detail_header {
	li {
		margin-bottom: 10px;
	}

	&-title {
		display: inline-block;
		font-weight: bold;
		width: 100px;
	}
}
</style>

<script>
import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import MenuModal from '@/components/MenuModal'
import CreditNoteItemTable from '@/components/CreditNoteItemTable'
import moment from 'moment'

export default {
	name: 'CreditNoteDetail',
	components: {
		CardComponent,
		TitleBar,
		CreditNoteItemTable,
	},
	data () {
		return {
			isMailLoading: false,
			isDlLoading: false,
			id: '',
			date: '',
			comment: '',
			storeName: '',
			number: '',
			items: [],
		}
	},
	computed: {
		titleStack () {
			return [
				this.$route.meta.title,
			]
		},
	},
	mounted() {
		this.init();
	},
	methods: {
		init: function() {
			this.getData();
		},
		getData: async function() {
			let loader = this.$buefy.loading.open();
			let ep = 'credit-notes/' + this.$route.params.creditNoteId;
			this.$utils.log(ep);

			try {
				let res = await this.$api.get(ep, null, true);
				this.$utils.log(res);
				this.number = res.data.number;
				this.date = res.data.date;
				this.storeName = res.data.store_name;
				this.id = res.data.id;
				this.comment = res.data.comment;

				let items = res.data.items;
				for (let i in items) {
					this.items.push({
						id: i + 1,
						menuCode: items[i].menu_code,
						menuBrandName: items[i].menu_brand_name,
						menuName: items[i].menu_name,
						menuPrice: items[i].menu_price,
						menuTaxRate: items[i].menu_tax_rate,
						quantity: items[i].quantity,
					});
				}
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				loader.close();
			}
		},
		onMailClick: async function() {
			if (!window.confirm('加盟店にマイナス伝票をメール送信します。よろしいですか？')) return false;

			this.isMailLoading = true;
			let data = {
				"id": this.$route.params.creditNoteId,
			};

			this.$utils.log(data);

			try {
				let ep = 'credit-notes/send';

				let res = await this.$api.post(ep, data, true);
				this.$utils.log(res);

				this.$utils.toastSuccess('送信しました');
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isMailLoading = false;
			}
		},
		onDlClick: async function() {
			this.isDlLoading = true;

			let ep = 'credit-notes/download';
			this.$utils.log(ep);

			let data = {
				"id": this.$route.params.creditNoteId,
			};
			this.$utils.log(data);

			try {
				let res = await this.$api.post(ep, data, true);
				this.$utils.log(res);

				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(this.$utils.toBlob(res.data.body, res.data.mime_type))
				link.download = res.data.file_name;
				link.click()
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isDlLoading = false;
			}
		},
	}
}
</script>
