<template>
	<b-modal :active.sync="isModalActive" has-modal-card class="menu-modal">
		<div class="modal-card">
			<header class="modal-card-head">
				<p class="modal-card-title">オーダー追加</p>
			</header>
			<section class="modal-card-body">
				<div>
					<dl v-if="orderId === ''">
						<dt>
							加盟店
						</dt>
						<dd>
							<b-select v-model="storeId">
								<option v-for="(row, index) in stores" :key="index" :value="row.id">
									{{ row.name }}
								</option>
							</b-select>
						</dd>
					</dl>

					<dl>
						<dt>
							ブランド
						</dt>
						<dd>
							<b-select v-model="brandId" @input="onBrandChange">
								<option v-for="(row, index) in brands" :key="index" :value="row.id">
									{{ row.name }}
								</option>
							</b-select>
						</dd>
					</dl>

					<dl>
						<dt>
							メニュー
						</dt>
						<dd>
							<b-select v-model="menuId">
								<option v-for="(row, index) in menus" :key="index" :value="row.id">
									{{ row.code }} {{ row.name }} {{ row.quantity }}
								</option>
							</b-select>
						</dd>
					</dl>

					<dl>
						<dt></dt>
						<dd>
							<div class="menu">
								<b-numberinput v-model="menuQty" min="0" class="menu-qty"></b-numberinput>

								<button class="button is-info menu-add" type="button" @click="addMenuClick" :disabled="canNotAdd">追加</button>
							</div>
						</dd>
					</dl>
				</div>

				<p v-show="orders.length > 0" class="mt-40 mb-10" style="font-weight:bold">■ 追加メニュー</p>
				<ul class="orders">
					<li v-for="(row, index) in orders" :key="index">
						<b-button type="is-danger" size="is-medium" icon-right="close" @click="onOrderRemove(index)" />
						<span>［{{ row.brand }}］{{ row.code }} {{ row.name }} {{ row.quantity }}：{{ row.qty }}{{ row.unit}}</span>
					</li>
				</ul>

				<b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
			</section>
			<footer class="modal-card-foot">
				<button class="button mr-30" type="button" @click="cancel">キャンセル</button>
				<button class="button is-success" type="button" @click="update" :disabled="canNotUpdate">更新</button>
			</footer>
		</div>
	</b-modal>
</template>

<script>
export default {
	name: 'MenuModal',
	props: {
		isActive: {
			type: Boolean,
			default: false
		},
		orderId: {
			type: String,
			default: '',
		},
	},
	data () {
		return {
			isLoading: false,
			isModalActive: false,
			brandId: '',
			brands: [],
			menuId: '',
			menus: [],
			allMenus: [],
			menuQty: 0,
			orders: [],
			storeId: '',
			stores: [],
		}
	},
	computed: {
		canNotUpdate: function() {
			return this.orders.length === 0;
		},
		canNotAdd: function() {
			return this.orders.filter((value) => { return value.id === this.menuId }).length > 0 || this.menuQty === 0 || this.menuQty === '';
		}
	},
	mounted () {
	},
	methods: {
		onBrandChange: async function() {
			this.$utils.log('onBrandChange');
			this.menus = [];
			this.menuQty = 0;
			this.menus = this.allMenus[this.brandId];
			this.menuId = this.menus[0].id;
		},
		onOrderRemove: function(index) {
			this.orders.splice(index, 1);
		},
		addMenuClick: function() {
			let brand = this.brands.filter((value) => { return value.id === this.brandId })[0];
			let menu = this.menus.filter((value) => { return value.id === this.menuId })[0];
			this.$utils.log(brand);

			this.orders.push({
				'id': menu.id,
				'brand': brand.name,
				'name': menu.name,
				'code': menu.code,
				'quantity': menu.quantity,
				'qty': this.menuQty,
				'unit': menu.unit,
			});

			this.menuQty = 0;
		},
		cancel: function() {
			this.$emit('cancel');
		},
		update: function() {
			let data = {
				'store_id': this.storeId,
				'orders': this.orders,
			};
			this.$emit('update', data);
			this.isModalActive = false;
		},
		init: function() {
			this.brandId = '';
			this.brands = [];
			this.menuId = '';
			this.menus = [];
			this.allMenus = [];
			this.menuQty = 0;
			this.orders = [];
			this.storeId = '';
			this.stores = [];

			this.getData();
		},
		getData: async function() {
			this.isLoading = true;
			let ep = 'orders/modal-screen/' + this.orderId;
			this.$utils.log(ep);

			try {
				let res = await this.$api.get(ep, null, true);
				this.$utils.log(res);
				this.brands = res.data.brands;
				this.brandId = this.brands[0].id;
				this.menus = res.data.menus[this.brandId];
				this.allMenus = res.data.menus;
				this.menuId = this.menus[0].id;
				this.stores = res.data.stores;
				this.storeId = this.stores[0].id;
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isLoading = false;
			}
		},
	},
	watch: {
		isActive (newValue) {
			this.isModalActive = newValue;
			if (newValue) this.init();
		},
		isModalActive (newValue) {
			if (!newValue) {
				this.cancel();
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.orders {
	li {
		display: flex;
		align-items: center;
		margin-bottom: 15px;

		span {
			display: inline-block;
		}

		.button {
			max-width: 30px;
			max-height: 30px;
			min-width: 30px;
			min-height: 30px;
			padding: 0;
			margin-right: 10px;
		}
	}
}

.modal-card {
	width: 760px;
}

.modal-card-body dl,
.menu {
	display: flex;
	align-items: center;
}

::v-deep .modal-card-body select {
	width: 100%;
}

.modal-card-body dl {
	margin-bottom: 20px;
}

.modal-card-body dt {
	font-weight: bold;
	min-width: 80px;
}

.field:not(:last-child) {
	margin-bottom: 0;
}

.menu {
	&-qty {
		margin-right: 30px;

		::v-deep input[type=number] {
			width: 80px;
		}
		::v-deep .button {
			width: 60px;
		}
	}

	&-add {
		width: 80px;
	}
}
</style>
