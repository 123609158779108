<template>
	<div>
		<title-bar :title-stack="titleStack"/>

		<section class="section is-main-section">
			<card-component title="" icon="">
				<b-field label="発行日"
					horizontal
					custom-class="require"
					:type="{ 'is-danger': $utils.hasError(errors, 'date') }"
					:message="errors.date">
					<date-picker class="date_select-input" :allowInput="false" :dateProvided="date" @input="onDateInput"></date-picker>
				</b-field>

				<b-field label="加盟店" custom-class="require" horizontal>
					<b-select v-model="storeId">
						<option v-for="(row, index) in stores" :key="index" :value="row.id">
							{{ row.name }}
						</option>
					</b-select>
				</b-field>

				<b-field label="伝票番号" horizontal>
					<b-input type="text" v-model="number" placeholder="社内管理で必要であれば入力"/>
				</b-field>

				<b-field class="mt-40" label="商品" horizontal>
					<b-button type="is-success" @click="onAddClick">追加</b-button>
				</b-field>

				<b-field class="mb-40" label="" horizontal>
					<credit-note-item-table :items="items" @delete="onItemDelete"></credit-note-item-table>
				</b-field>

				<b-field label="コメント（加盟店へ送信するメールに表示されます）" horizontal>
					<b-input type="textarea" v-model="comment"></b-input>
				</b-field>

				<b-field grouped class="mt-40">
					<div class="control">
						<b-button @click="$router.go(-1)">戻る</b-button>
					</div>
					<div class="control">
						<b-button class="ml-20" :disabled="isSubmitBtnDisabled" type="is-info" :loading="isLoading" @click="onSubmitClick">保存</b-button>
					</div>
				</b-field>
			</card-component>
		</section>

		<b-modal :active.sync="isAddModalActive" :width="600" class="edit-modal">
			<div class="modal-card" style="width: auto">
				<header class="modal-card-head">
					<p class="modal-card-title">商品追加</p>
				</header>

				<section class="modal-card-body">
					<b-select v-model="brandId" class="mb-20" @input="onBrandChange">
						<option value="">ブランドを選択</option>
						<option v-for="(row, index) in brands" :key="index" :value="row.id">
							{{ row.name }}
						</option>
					</b-select>

					<div class="menu-wrapper mb-40">
						<b-select v-model="menuId" class="modal-menu">
							<option value="">メニューを選択</option>
							<option v-for="(row, index) in menus" :key="index" :value="row.id">
								{{ row.name }}
							</option>
						</b-select>
						<b-button class="ml-30" :disabled="isMenuSetBtnDisabled" label="下にセット" type="is-success" @click="onSetMenuClick" />
					</div>

					<b-field label="品番" horizontal>
						<b-input type="text" class="short" v-model="menuCode"></b-input>
					</b-field>

					<b-field label="ブランド" horizontal>
						<b-input type="text" v-model="menuBrandName"></b-input>
					</b-field>

					<b-field label="商品名"
						horizontal
						custom-class="require"
						:type="{ 'is-danger': $utils.hasError(modalErrors, 'menuName') }"
						:message="modalErrors.menuName">
						<b-input type="text" v-model="menuName"></b-input>
					</b-field>

					<b-field label="課税区分" custom-class="require" horizontal>
						<b-select v-model="menuTaxRate">
							<option v-for="(row, index) in taxClasses" :key="index" :value="row">
								{{ row }}%
							</option>
						</b-select>
					</b-field>

					<b-field label="価格(税抜)"
						horizontal
						custom-class="require"
						:type="{ 'is-danger': $utils.hasError(modalErrors, 'menuPrice') }"
						:message="modalErrors.menuPrice">
						<b-input type="text" class="short" v-model="menuPrice"></b-input>
					</b-field>

					<b-field label="数量"
						horizontal
						custom-class="require"
						:type="{ 'is-danger': $utils.hasError(modalErrors, 'quantity') }"
						:message="modalErrors.quantity">
						<div class="minus-wrapper">
							<span class="mr-10">▲</span>
							<b-input type="text" class="number-w" v-model="quantity"></b-input>
						</div>
					</b-field>
				</section>

				<footer class="modal-card-foot">
					<b-button label="キャンセル" @click="isAddModalActive= false" />
					<b-button class="ml-30" label="追加" type="is-primary" @click="onAddItemClick" />
				</footer>
			</div>
		</b-modal>
	</div>
</template>

<style lang="scss" scoped>
@import '../scss/_mixins.scss';

.date_select-input {
	width: 180px;
}

.menu-wrapper {
	@include flex;
}

.minus-wrapper {
	@include flex;
}

.short {
	width: 226px;
}

.number-w {
	width: 80px;
}

.modal-menu {
	max-width: 350px;
}
</style>

<script>
import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import RadioPicker from '@/components/RadioPicker'
import DatePicker from '@/components/DatePicker'
import ImagePicker from '@/components/ImagePicker'
import CreditNoteItemTable from '@/components/CreditNoteItemTable'
import moment from 'moment'

export default {
	name: 'CreditNoteEdit',
	components: {
		CardComponent,
		TitleBar,
		DatePicker,
		CreditNoteItemTable,
	},
	data () {
		return {
			id: '',
			errors: {
				date: '',
			},
			modalErrors: {
				menuName: '',
				quantity: '',
				menuPrice: '',
				menuTaxRate: '',
			},
			date: '',
			number: '',
			storeId: '',
			stores: [],
			comment: '',
			items: [],
			brands: [],
			brandId: '',
			allMenus: [],
			menus: [],
			menuId: '',
			taxClasses: [10, 8],
			isAddModalActive: false,
			menuName: '',
			menuPrice: '',
			menuCode: '',
			menuBrandName: '',
			quantity: '',
			menuTaxRate: '',
			isLoading: false,
		}
	},
	computed: {
		titleStack () {
			return [
				this.$route.meta.title,
			]
		},
		isSubmitBtnDisabled: function() {
			return !(this.date != '' && this.items.length > 0);
		},
		isMenuSetBtnDisabled: function() {
			return this.menuId === '';
		},
	},
	created() {
		this.date = moment().format('YYYY-MM-DD');

		/*
		this.items.push({
			menuCode: '',
			menuBrandName: '',
			menuName: '',
			menuTaxClassId: 2,
			menuPrice: 1000,
			quantity: 10,
			taxName: '',
		});
		this.items.push({
			id: 1,
			menuCode: 'test01',
			menuBrandName: 'ブランド1',
			menuName: 'メニュー①',
			menuPrice: 1000,
			menuTaxRate: 8,
			quantity: 10,
		});
		this.items.push({
			id: 2,
			menuCode: '',
			menuBrandName: '',
			menuName: '送料',
			menuPrice: 1500,
			menuTaxRate: 10,
			quantity: 1,
		});
		*/
	},
	mounted() {
		this.init();
	},
	methods: {
		onItemDelete: function(id) {
			this.items = this.items.filter((value) => { return value.id !== id });
		},
		onAddItemClick: function() {
			if (!this.isValidMenuModal()) {
				this.$utils.toastError('入力エラーがあります');
				return false;
			}

			this.items.push({
				menuCode: this.menuCode,
				menuBrandName: this.menuBrandName,
				menuName: this.menuName,
				menuTaxRate: parseInt(this.menuTaxRate),
				menuPrice: this.menuPrice,
				quantity: this.quantity,
			});

			for (let i = 0; i < this.items.length; i++) {
				this.items[i].id = i + 1;
			}

			this.isAddModalActive = false;
		},
		isValidMenuModal: function() {
			let isValid = true;

			this.clearMenuModalInputError();

			if (this.menuName === '') {
				this.modalErrors.menuName = '必須入力です';
				isValid = false;
			}

			if (this.menuPrice === '') {
				this.modalErrors.menuPrice = '必須入力です';
				isValid = false;
			}

			if (this.quantity === '') {
				this.modalErrors.quantity = '必須入力です';
				isValid = false;
			}

			if (String(this.menuPrice).match(/^\d+$/) === null) {
				this.modalErrors.menuPrice = '数字を入力してください';
				isValid = false;
			} else if (parseInt(this.menuPrice) <= 0) {
				this.modalErrors.menuPrice = '1以上の数字を入力してください';
				isValid = false;
			}

			if (String(this.quantity).match(/^\d+$/) === null) {
				this.modalErrors.quantity = '数字を入力してください';
				isValid = false;
			} else if (parseInt(this.quantity) <= 0) {
				this.modalErrors.quantity = '1以上の数字を入力してください';
				isValid = false;
			}

			return isValid;
		},
		onBrandChange: function() {
			this.menuId = '';
			this.menus = [];
			if (this.brandId !== '') this.menus = this.allMenus[this.brandId];
		},
		onSetMenuClick: function() {
			this.clearMenuModalInput();
			this.clearMenuModalInputError();
			let menu = this.menus.filter((value) => { return value.id === this.menuId })[0];
			this.$utils.log(menu);
			this.menuCode = menu.code;
			this.menuBrandName = menu.brand_name;
			this.menuName = menu.name;
			this.menuPrice = menu.price;
		},
		clearMenuModalInput: function() {
			this.menuCode = '';
			this.menuBrandName = '';
			this.menuName = '';
			this.menuPrice = '';
			this.menuTaxRate = '8';
			this.quantity = '';
		},
		clearMenuModalInputError: function() {
			this.modalErrors.menuName = '';
			this.modalErrors.quantity = '';
			this.modalErrors.menuPrice = '';
			this.modalErrors.menuTaxRate = '';
		},
		onDateInput: function(date) {
			this.date = date;
		},
		init: function() {
			this.getData();
		},
		getData: async function() {
			let loader = this.$buefy.loading.open();
			let params = this.$route.params.creditNoteId ? '/' + this.$route.params.creditNoteId : '';
			let ep = 'credit-notes/screen' + params;
			this.$utils.log(ep);

			try {
				let res = await this.$api.get(ep, null, true);
				this.$utils.log(res);
				this.id = res.data.id;
				this.number = res.data.number;
				if (res.data.date) this.date = res.data.date;
				this.storeId = res.data.store_id;
				this.stores = res.data.stores;
				this.brands = res.data.brands;
				this.allMenus = res.data.menus;
				this.comment = res.data.comment;
				this.menus = this.allMenus[this.brandId];
				if (res.data.items.length > 0) {
					let items = res.data.items;
					for (let i in items) {
						this.items.push({
							id: i + 1,
							menuCode: items[i].menu_code,
							menuBrandName: items[i].menu_brand_name,
							menuName: items[i].menu_name,
							menuPrice: items[i].menu_price,
							menuTaxRate: items[i].menu_tax_rate,
							quantity: items[i].quantity,
						});
					}
				}
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				loader.close();
			}
		},
		onSubmitClick: async function() {
			for (let key in this.errors) {
				this.errors[key] = '';
			}

			this.isLoading = true;
			let data = {
				"id": this.id,
				"date": this.date,
				"store_id": this.storeId,
				"number": this.number,
				"comment": this.comment,
			};

			let tmp = [];
			for (let item of this.items) {
				tmp.push({
					"menu_name": item.menuName,
					"quantity": parseInt(item.quantity),
					"menu_price": parseInt(item.menuPrice),
					"menu_tax_rate": parseInt(item.menuTaxRate),
					"menu_code": item.menuCode,
					"menu_brand_name": item.menuBrandName,
				});
			}

			data.credit_note_items = tmp;
			this.$utils.log(data);

			try {
				let ep = 'credit-notes';

				let res = null;
				if (!this.id) {
					res = await this.$api.post(ep, data, true);
				} else {
					res = await this.$api.put(ep + '/' + this.id, data, true);
				}

				this.$utils.toastSuccess('データを保存しました');
				this.$router.push('/credit-note-detail/' + res.data.id);
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
				if (error.response.status === 400) {
					let details = error.response.data.details;
					for (let key in details) {
						this.errors[this.$utils.toCamelCase(key)] = details[key];
					}
				}
			} finally {
				this.isLoading = false;
			}
		},
		onAddClick: function() {
			this.brandId = '';
			this.menuId = '';
			this.menus = [];
			this.clearMenuModalInput();
			this.clearMenuModalInputError();
			this.isAddModalActive = true;
		},
	}
}
</script>
