<template>
	<section>
		<b-table :data="items">
			<b-table-column v-if="deleteShow" label=" " v-slot="props">
				<b-button type="is-danger" class="is-small" icon-right="close" @click="onItemDeleteClick(props.row.id)"/>
			</b-table-column>

			<b-table-column label="ブランド名" v-slot="props">
				{{ props.row.menuBrandName }}
			</b-table-column>

			<b-table-column label="品番" v-slot="props">
				{{ props.row.menuCode }}
			</b-table-column>

			<b-table-column label="商品名" v-slot="props">
				{{ props.row.menuName }}
			</b-table-column>

			<b-table-column label="税率" centered v-slot="props">
				{{ props.row.menuTaxRate }}%
			</b-table-column>

			<b-table-column label="数量" numeric v-slot="props">
				▲{{ props.row.quantity.toLocaleString() }}
			</b-table-column>

			<b-table-column label="価格" numeric v-slot="props">
				{{ props.row.menuPrice.toLocaleString() }}
			</b-table-column>

			<b-table-column label="金額" numeric v-slot="props">
				▲{{ (props.row.quantity * props.row.menuPrice).toLocaleString() }}円
			</b-table-column>

			<template #footer>
				<div class="has-text-right">
					<ul class="summary">
						<li>
							<span class="summary-title">小計</span>
							<span class="summary-price">▲{{ subTotal }}円</span>
						</li>
						<li>
							<span class="summary-title">消費税(8%)</span>
							<span class="summary-price">▲{{ taxPrice2 }}円</span>
						</li>
						<li>
							<span class="summary-title">※消費税(10%)</span>
							<span class="summary-price">▲{{ taxPrice1 }}円</span>
						</li>
						<li class="mt-10">
							<span class="summary-title">合計</span>
							<span class="summary-price">▲{{ grandTotal }}円</span>
						</li>
					</ul>
				</div>
			</template>
		</b-table>
	</section>
</template>

<style lang="scss" scoped>
.table button {
	width: 30px;
}
.summary {
	li:not(:last-child) {
		margin-bottom: 5px;
		font-weight: normal;
	}

	li span {
		display: inline-block;
	}

	&-title {
		width: 120px;
		text-align: left;
	}

	&-price {
		width: 120px;
	}
}
</style>

<script>
export default {
	name: 'CreditNoteItemTable',
	props: {
		items: {
			type: Array,
			default: null,
		},
		deleteShow: {
			type: Boolean,
			default: true,
		},
	},
	data () {
		return {
		}
	},
	computed: {
		subTotal: function() {
			let num = 0;
			for (let item of this.items) {
				num += parseInt(item.menuPrice) * item.quantity;
			}
			return num.toLocaleString();
		},
		taxPrice1: function() {
			return this.sumTaxPrice(10).toLocaleString();
		},
		taxPrice2: function() {
			return this.sumTaxPrice(8).toLocaleString();
		},
		grandTotal: function() {
			let num = 0;
			for (let item of this.items) {
				num += parseInt(item.menuPrice) * item.quantity;
			}
			num += this.sumTaxPrice(10);
			num += this.sumTaxPrice(8);

			return num.toLocaleString();
		},
	},
	mounted () {
	},
	methods: {
		onItemDeleteClick: function(id) {
			this.$emit('delete', id);
		},
		sumTaxPrice: function(taxRate) {
			let num = 0;
			for (let item of this.items) {
				if (item.menuTaxRate === taxRate) num += parseInt(item.menuPrice) * item.quantity * (item.menuTaxRate / 100);
			}
			return Math.floor(num);
		},
	},
}
</script>
