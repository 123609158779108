<template>
	<div>
		<title-bar :title-stack="titleStack"/>

		<section class="section is-main-section">
			<card-component class="has-table has-mobile-sort-spaced" :hasButtonSlot="true" title="一覧" icon="format-list-bulleted">
				<b-button slot="button" type="is-warning is-small" @click="onStoreListClick" :loading="isStoreListLoading" v-if="$store.state.isFp">加盟店リスト</b-button>
				<b-button slot="button" :disabled="!isNewOrderVisible" type="is-info is-small" @click="isMenuModalActive = true">新規オーダー</b-button>
				<b-button slot="button" type="is-success is-small" @click="onInvoiceClick" :loading="isInvoiceLoading" :disabled="isInvoiceDisabled">納品書作成</b-button>

				<div class="date_select">
					<b-button type="is-success" icon-pack="fas" icon-right="chevron-left" @click="onDatePreviousClick"/>
					<date-picker class="date_select-input" :allowInput="false" :dateProvided="targetDate" @input="onDateInput"></date-picker>
					<b-button type="is-success" icon-pack="fas" icon-right="chevron-right" @click="onDateNextClick"/>
				</div>

				<b-table
					:loading="isLoading"
					:paginated="false"
					:per-page="$consts.TABLE_PER_PAGE"
					:striped="true"
					:hoverable="true"
					default-sort=""
					:data="list"
					>

					<b-table-column label="加盟店" field="store_name" sortable v-slot="props">
						{{ props.row.store_name }}
					</b-table-column>
					<b-table-column label="注文番号" field="number" sortable v-slot="props">
						{{ props.row.number }}
					</b-table-column>
					<b-table-column label="送料" field="shipping_fee" v-slot="props">
						{{ props.row.shipping_fee.toLocaleString() }}円
					</b-table-column>
					<b-table-column label="小計" field="sub_total" v-slot="props">
						{{ props.row.sub_total.toLocaleString() }}円
					</b-table-column>
					<b-table-column label="消費税(8%)" field="tax_total" v-slot="props">
						{{ props.row.tax_class2.toLocaleString() }}円
					</b-table-column>
					<b-table-column label="消費税(10%)" field="tax_total" v-slot="props">
						{{ props.row.tax_class1.toLocaleString() }}円
					</b-table-column>
					<b-table-column label="合計" field="grand_total" v-slot="props">
						{{ props.row.grand_total.toLocaleString() }}円
					</b-table-column>
					<b-table-column label="発送" field="is_finish" sortable v-slot="props">
						<span v-if="props.row.is_finish">発送済み</span>
						<span v-else style="color:red">未発送</span>
					</b-table-column>

					<b-table-column label=" " v-slot="props">
						<div class="buttons is-right">
							<button class="button is-small is-info" type="button" @click="onDetailClick(props.row.id)">詳細</button>
							<button class="button is-small is-danger" type="button" :style="{ visibility: props.row.is_finish ? 'hidden' : 'visible' }" @click="onDeleteClick(props.row.id)">削除</button>
						</div>
					</b-table-column>

					<section class="section" slot="empty">
						<table-empty :loading="isLoading"></table-empty>
					</section>
				</b-table>
			</card-component>
		</section>

		<menu-modal
			:isActive="isMenuModalActive"
			@cancel="isMenuModalActive = false"
			@update="onOrderUpdateClick"
			></menu-modal>
	</div>

</template>

<style lang="scss" scoped>
@import '../scss/_mixins.scss';
.date_select {
	padding: 20px 10px;
	@include flex;

	&-input {
		width: 200px !important;
		margin: 0 10px;
	}

	.button {
		width: 50px ;
	}
}
</style>

<script>
import Notification from '@/components/Notification'
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import RefreshButton from '@/components/RefreshButton'
import CardToolbar from '@/components/CardToolbar'
import TableEmpty from '@/components/TableEmpty'
import PageInfo from '@/components/PageInfo'
import moment from 'moment'
import DatePicker from '@/components/DatePicker'
import MenuModal from '@/components/MenuModal'

export default {
	name: 'Order',
	components: {
		TitleBar,
		CardComponent,
		TableEmpty,
		DatePicker,
		MenuModal,
	},
	data () {
		return {
			isLoading: false,
			list: [],
			targetDate: '',
			isInvoiceLoading: false,
			isStoreListLoading: false,
			isDeleteLoading: false,
			isMenuModalActive: false,
		}
	},
	computed: {
		titleStack () {
			return [
				this.$route.meta.title,
			]
		},
		isInvoiceDisabled: function() {
			return this.list.length === 0;
		},
		isNewOrderVisible: function() {
			return this.targetDate === moment().add('-1', 'days').format('YYYY-MM-DD');
		},
	},
	mounted () {
		this.init();
	},
	methods: {
		onOrderUpdateClick: async function(modalData) {
			this.$utils.log('onOrderUpdateClick');
			//this.$utils.log(modalData);
			let orders = {};
			for (let i in modalData.orders) {
				orders[modalData.orders[i].id] = modalData.orders[i].qty;
			}
			//this.$utils.log(orders);

			let data = {
				"store_id": modalData.store_id,
				"order_id": '',
				"orders": orders,
			};
			this.$utils.log(data);
 
			this.isLoading = true;

			try {
				let ep = 'orders/update';

				let res = await this.$api.post(ep, data, true);
				this.$utils.log(res);
				this.getData();
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isLoading = false;
			}
		},
		onDetailClick: function(id) {
			this.$store.commit('basic', {
				key: 'orderDate',
				value: this.targetDate,
			})
			this.$router.push({ name: 'order-detail', params: { orderId: id }});
		},
		onDeleteClick: async function(id) {
			if (!window.confirm('オーダーを削除します。よろしいですか？')) return false;

			this.isDeleteLoading = true;

			let ep = 'orders/' + id;
			this.$utils.log(ep);

			try {
				let res = await this.$api.delete(ep, null, true);
				this.$utils.log(res);
				this.getData();
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isDeleteLoading = false;
			}
		},
		onStoreListClick: async function() {
			this.isStoreListLoading = true;

			let ep = 'orders/stores';
			this.$utils.log(ep);

			try {
				let res = await this.$api.get(ep, null, true);
				this.$utils.log(res);
				this.downloadCSV(res.data);
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isStoreListLoading = false;
			}
		},
		downloadCSV: function(data) {
			let fileName = `加盟店リスト.csv`;
			this.$utils.downloadCSV(data, fileName, true);
		},
		onInvoiceClick: async function() {
			this.isInvoiceLoading = true;

			let ep = 'orders/invoice';
			this.$utils.log(ep);

			let data = {
				"date": this.targetDate,
			};
			this.$utils.log(data);

			try {
				let res = await this.$api.post(ep, data, true);
				this.$utils.log(res);

				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(this.$utils.toBlob(res.data.body, res.data.mime_type))
				link.download = res.data.file_name;
				link.click()
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isInvoiceLoading = false;
			}
		},
		onDateNextClick: function() {
			if (this.targetDate === '') {
				this.targetDate = moment().add('-1', 'days').format('YYYY-MM-DD');
			} else {
				this.targetDate = moment(this.targetDate).add('+1', 'days').format('YYYY-MM-DD');
			}
			this.getData();
		},
		onDatePreviousClick: function() {
			if (this.targetDate === '') {
				this.targetDate = moment().add('-1', 'days').format('YYYY-MM-DD');
			} else {
				this.targetDate = moment(this.targetDate).add('-1', 'days').format('YYYY-MM-DD');
			}
			this.getData();
		},
		onDateInput: function(date) {
			this.targetDate = date;
			if (this.targetDate !== '') this.getData();
		},
		init: function() {
			let storedDate = this.$store.state.orderDate;
			this.targetDate = storedDate !== '' ? storedDate : moment().add('-1', 'days').format('YYYY-MM-DD');

			this.$store.commit('basic', {
				key: 'orderDate',
				value: '',
			})

			this.getData();
		},
		getData: async function() {
			this.isLoading = true;
			let ep = 'orders?date=' + this.targetDate;
			this.$utils.log(ep);

			try {
				let res = await this.$api.get(ep, null, true);
				this.$utils.log(res);
				this.list = res.data.list;
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isLoading = false;
			}
		},
	},
}
</script>
