<template>
	<div>
		<title-bar :title-stack="titleStack"/>

		<section class="section is-main-section">
			<card-component class="has-table has-mobile-sort-spaced" :hasButtonSlot="true" title="一覧" icon="format-list-bulleted">
				<b-button slot="button" type="is-success is-small" @click="$router.push({ name: 'credit-note-new' })">新規作成</b-button>

				<div class="month_select ml-10 mt-10 mb-30">
					<b-button type="is-success" icon-pack="fas" icon-right="chevron-left" :disabled="isPreviousMonthButtonDisable" @click="onMonthPreviousClick"/>

					<b-select v-model="year" @input="onYearChange">
						<option v-for="row in years" :key="row" :value="row">
							{{ row }}
						</option>
					</b-select>

					<span>/</span>

					<b-select v-model="month" @input="onMonthChange">
						<option v-for="n of 12" :key="n" :value="$utils.zeroPadding(n, 2)">
							{{ n }}
						</option>
					</b-select>

					<b-button type="is-success" icon-pack="fas" icon-right="chevron-right" :disabled="isNextMonthButtonDisable" @click="onMonthNextClick"/>
				</div>

				<b-table
					:loading="isLoading"
					:paginated="false"
					:per-page="$consts.TABLE_PER_PAGE"
					:striped="true"
					:hoverable="true"
					default-sort=""
					:data="list"
					>

					<b-table-column label="発行日" field="date" sortable v-slot="props">
						{{ props.row.date }}
					</b-table-column>
					<b-table-column label="加盟店" field="store_name" sortable v-slot="props">
						{{ props.row.store_name }}
					</b-table-column>
					<b-table-column label="伝票番号" field="number" sortable v-slot="props">
						{{ props.row.number }}
					</b-table-column>
					<b-table-column label="金額" field="grand_total" sortable numeric v-slot="props">
						▲{{ props.row.grand_total.toLocaleString() }}円
					</b-table-column>

					<b-table-column label=" " v-slot="props">
						<div class="buttons is-right">
							<button class="button is-small is-info" type="button" @click="onDetailClick(props.row.id)">詳細</button>
							<button class="button is-small is-warning" type="button" @click="onEditClick(props.row.id)">編集</button>
							<button class="button is-small is-danger" type="button" @click="onDeleteClick(props.row.id)">削除</button>
						</div>
					</b-table-column>

					<section class="section" slot="empty">
						<table-empty :loading="isLoading"></table-empty>
					</section>
				</b-table>
			</card-component>
		</section>
	</div>

</template>

<style lang="scss" scoped>
@import '../scss/_mixins.scss';
.month_select {
	@include flex;

	&-input {
		width: 180px !important;
	}

	span {
		margin: 0 10px;
	}

	.button {
		width: 40px ;
		&:first-child {
			margin-right: 10px;
		}
		&:last-child {
			margin-left: 10px;
		}
	}
}

.date_select {
	@include flex($wrap:wrap);

	&-input {
		width: 180px !important;
	}

	span {
		margin: 0 10px;
	}
}
</style>

<script>
import Notification from '@/components/Notification'
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import RefreshButton from '@/components/RefreshButton'
import CardToolbar from '@/components/CardToolbar'
import TableEmpty from '@/components/TableEmpty'
import PageInfo from '@/components/PageInfo'
import moment from 'moment'
import DatePicker from '@/components/DatePicker'
import MenuModal from '@/components/MenuModal'

export default {
	name: 'CreditNote',
	components: {
		TitleBar,
		CardComponent,
		TableEmpty,
	},
	data () {
		return {
			isLoading: false,
			list: [],
			targetDate: '',
			year: moment().format('YYYY'),
			month: moment().format('MM'),
			years: [],
		}
	},
	computed: {
		titleStack () {
			return [
				this.$route.meta.title,
			]
		},
		isPreviousMonthButtonDisable: function() {
			return this.years[0] > this.previousYear;
		},
		isNextMonthButtonDisable: function() {
			return this.years[this.years.length - 1] < this.nextYear;
		},
		currentMonth: function() {
			return `${this.year}-${this.month}-01`;
		},
		previousYear: function() {
			return moment(this.currentMonth).add(-1, 'month').startOf('month').format('YYYY');
		},
		nextYear: function() {
			return moment(this.currentMonth).add(+1, 'month').startOf('month').format('YYYY');
		},
		previousMonth: function() {
			return moment(this.currentMonth).add(-1, 'month').startOf('month').format('MM');
		},
		nextMonth: function() {
			return moment(this.currentMonth).add(+1, 'month').startOf('month').format('MM');
		},
	},
	created () {
		for (let i = 2021; i <= moment().format('YYYY'); i++) {
			this.years.push(i);
		}
	},
	mounted () {
		this.init();
	},
	methods: {
		onMonthPreviousClick: function() {
			this.year = this.previousYear;
			this.month = this.previousMonth;
			this.onChangeCondition();
		},
		onMonthNextClick: function() {
			this.year = this.nextYear;
			this.month = this.nextMonth;
			this.onChangeCondition();
		},
		onYearChange: function() {
			this.onChangeCondition();
		},
		onMonthChange: function() {
			this.onChangeCondition();
		},
		onChangeCondition: function() {
			this.targetDate = `${this.year}-${this.month}-01`;
			this.getData();
		},
		init: function() {
			let storedDate = this.$store.state.creditNoteDate;
			if (storedDate !== '') {
				this.targetDate = storedDate;
				let dates = storedDate.split('-');
				this.year = dates[0];
				this.month = dates[1];
			} else {
				this.targetDate = moment().format('YYYY-MM-DD');
			}

			this.$store.commit('basic', {
				key: 'creditNoteDate',
				value: '',
			})

			this.getData();
		},
		getData: async function() {
			this.isLoading = true;
			let ep = 'credit-notes?date=' + this.targetDate;
			this.$utils.log(ep);

			try {
				let res = await this.$api.get(ep, null, true);
				this.$utils.log(res);
				this.list = res.data.list;
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isLoading = false;
			}
		},
		onEditClick: function(id) {
			this.$store.commit('basic', {
				key: 'creditNoteDate',
				value: this.targetDate,
			})
			this.$router.push({ name: 'credit-note-edit', params: { creditNoteId: id }});
		},
		onDetailClick: function(id) {
			this.$store.commit('basic', {
				key: 'creditNoteDate',
				value: this.targetDate,
			})
			this.$router.push({ name: 'credit-note-detail', params: { creditNoteId: id }});
		},
		onDeleteClick: async function(id) {
			if (!window.confirm('伝票を削除します。よろしいですか？')) return false;

			this.isLoading = true;

			let ep = 'credit-notes/' + id;
			this.$utils.log(ep);

			try {
				let res = await this.$api.delete(ep, null, true);
				this.$utils.log(res);
				this.getData();
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isLoading = false;
			}
		},
		/*
		onOrderUpdateClick: async function(modalData) {
			this.$utils.log('onOrderUpdateClick');
			//this.$utils.log(modalData);
			let orders = {};
			for (let i in modalData.orders) {
				orders[modalData.orders[i].id] = modalData.orders[i].qty;
			}
			//this.$utils.log(orders);

			let data = {
				"store_id": modalData.store_id,
				"order_id": '',
				"orders": orders,
			};
			this.$utils.log(data);
 
			this.isLoading = true;

			try {
				let ep = 'orders/update';

				let res = await this.$api.post(ep, data, true);
				this.$utils.log(res);
				this.getData();
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isLoading = false;
			}
		},
		onStoreListClick: async function() {
			this.isStoreListLoading = true;

			let ep = 'orders/stores';
			this.$utils.log(ep);

			try {
				let res = await this.$api.get(ep, null, true);
				this.$utils.log(res);
				this.downloadCSV(res.data);
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isStoreListLoading = false;
			}
		},
		downloadCSV: function(data) {
			let fileName = `加盟店リスト.csv`;
			this.$utils.downloadCSV(data, fileName, true);
		},
		onInvoiceClick: async function() {
			this.isInvoiceLoading = true;

			let ep = 'orders/invoice';
			this.$utils.log(ep);

			let data = {
				"date": this.targetDate,
			};
			this.$utils.log(data);

			try {
				let res = await this.$api.post(ep, data, true);
				this.$utils.log(res);

				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(this.$utils.toBlob(res.data.body, res.data.mime_type))
				link.download = res.data.file_name;
				link.click()
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				this.isInvoiceLoading = false;
			}
		},
		onDateNextClick: function() {
			if (this.targetDate === '') {
				this.targetDate = moment().add('-1', 'days').format('YYYY-MM-DD');
			} else {
				this.targetDate = moment(this.targetDate).add('+1', 'days').format('YYYY-MM-DD');
			}
			this.getData();
		},
		onDatePreviousClick: function() {
			if (this.targetDate === '') {
				this.targetDate = moment().add('-1', 'days').format('YYYY-MM-DD');
			} else {
				this.targetDate = moment(this.targetDate).add('-1', 'days').format('YYYY-MM-DD');
			}
			this.getData();
		},
		onDateInput: function(date) {
			this.targetDate = date;
			if (this.targetDate !== '') this.getData();
		},
		*/
	},
}
</script>
